/** @jsx jsx */
import { string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'
import EmailSubscribeForm from '~/components/EmailSubscribeForm'

import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderRichText from '~/content/renderRichText'
import ContentLeftLayout from '~/layout/ContentLeftLayout'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'EmailSubscribeHeroSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType,
  elementId: string,
  heading: string,
}

const Component = ({ body, elementId, heading }) => (
  <Section
    sx={{ paddingY: [5, null, 'unset'] }}
    id={elementId}
  >
    <Container>
      <ContentLeftLayout>
        <div
          sx={{
            minHeight: [null, null, 'fullHeightContentDesktop'],
            display: [null, null, 'flex'],
            alignItems: [null, null, 'center'],
          }}
        >
          <IntroTransition>
            <Heading
              as="h2"
              variant="headingHero"
              mb={1}
            >
              {heading}
            </Heading>
            <div sx={{ mb: 3 }}>
              {renderRichText(body, { style: 'prospect' })}
            </div>
            <EmailSubscribeForm focusOnLoad />
          </IntroTransition>
        </div>
      </ContentLeftLayout>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
